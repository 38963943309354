import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./404.module.css"
import Img from 'gatsby-image';

const NotFoundPage = ({ data }) => (
  <Layout headerBackground={"#fff"} navColor={"#222"}>
    <SEO
      title={`404 Not Found`}
    />
    <div className={styles.wrapper}>
      <div className={styles.mario404}>
        <Img fluid={data.mario.childImageSharp.fluid} />
      </div>
      <h1>Page Not Found</h1>
    </div>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    mario: file(relativePath: { eq: "404mario-01.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`